import mod_organization from "../../mods/organization";
import mod_game from "../../mods/game";
import mod_base from "../../mods/base";

export const name = 'organization';

export const mounts = [
    {path: '/', mod: mod_organization},
    {path: '/g/:code', mod: mod_game},
    {path: '/', mod: mod_base},
];