import mod_buy from "../../mods/buy";
import mod_buy_book from "../../mods/buy_book";
import mod_base from "../../mods/base";
import mod_service from "../../mods/service";

export const name = 'sales';

export const mounts = [
    {path: '/g/:code', mod: mod_buy},
    {path: '/k/:code', mod: mod_buy_book},
    {path: '/', mod: mod_service},
    {path: '/', mod: mod_base},
];