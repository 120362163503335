import mod_error from "../../mods/error";
import mod_base from "../../mods/base";
import mod_service from "../../mods/service";

export const name = 'errors';

export const mounts = [
    {path: '/', mod: mod_error},
    {path: '/', mod: mod_service},
    {path: '/', mod: mod_base},
];