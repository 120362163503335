import mod_game from "../../mods/game";
import mod_organization from "../../mods/organization";
import mod_campaign from "../../mods/campaign";
import mod_base from "../../mods/base";
import mod_sale from "../../mods/sale";
import mod_ticket from "../../mods/ticket";
import mod_sellergroup from "../../mods/sellergroup";
import mod_seller from "../../mods/seller";
import mod_sponsoring from "../../mods/sponsoring";
import mod_godfather from "../../mods/godfather";
import mod_promise from "../../mods/promise";
import mod_presult from "../../mods/presult";
import mod_preorder from "../../mods/preorder";
import mod_order from "../../mods/order";
import mod_tenant from "../../mods/tenant";
import mod_service from "../../mods/service";
import mod_book from "../../mods/book";
import mod_bookpayment from "../../mods/bookpayment";
import mod_sponsor from "../../mods/sponsor";
import mod_donee from "../../mods/donee";

export const name = 'pages';

export const mounts = [
    {path: '/g/:code', mod: mod_game},
    {path: '/o/:code', mod: mod_organization},
    {path: '/c/:code', mod: mod_campaign},
    {path: '/x/:code', mod: mod_tenant},
    {path: '/s/:id', mod: mod_sale},
    {path: '/t/:code', mod: mod_ticket},
    {path: '/j/:code', mod: mod_sellergroup},
    {path: '/h/:code', mod: mod_seller},
    {path: '/z/:code', mod: mod_godfather},
    {path: '/sp/:code', mod: mod_sponsoring},
    {path: '/m/:id', mod: mod_promise},
    {path: '/pr/:compositeToken', mod: mod_presult},
    {path: '/y/:id', mod: mod_preorder},
    {path: '/k/:code', mod: mod_book},
    {path: '/v/:id', mod: mod_bookpayment},
    {path: '/f/:code', mod: mod_sponsor},
    {path: '/do/:code', mod: mod_donee},
    {path: '/to/:id', mod: mod_order},
    {path: '/', mod: mod_service},
    {path: '/', mod: mod_base},
];

export const sitemaps = {
    campaigns: {type: 'campaigns'},
    games: {},
    misc: {items: [{uri: '/'}]},
};