import mod_game from "../../mods/game";
import mod_base from "../../mods/base";
import mod_sale from "../../mods/sale";
import mod_ticket from "../../mods/ticket";
import mod_promise from "../../mods/promise";
import mod_presult from "../../mods/presult";
import mod_preorder from "../../mods/preorder";
import mod_buy from "../../mods/buy";

export const name = 'game';

export const mounts = [
    {path: '/', mod: mod_game},
    {path: '/go', mod: mod_buy},
    {path: '/s/:id', mod: mod_sale},
    {path: '/t/:code', mod: mod_ticket},
    {path: '/m/:id', mod: mod_promise},
    {path: '/pr/:compositeToken', mod: mod_presult},
    {path: '/y/:id', mod: mod_preorder},
    {path: '/', mod: mod_base},
];